window.addEventListener('heyflow-screen-view', (event) => {

console.log('heyflow screen view:', event.detail);
if(event.detail.screenName === 'hsntsn'){
    
    

    
        
        
        let hsntsn_hsn = document.querySelector('[data-variable="hsntsn_HSN"]');
        let hsntsn_tsn = document.querySelector('[data-variable="hsntsn_TSN"]');
        console.log('hsntsn_hsn>>>',hsntsn_hsn.value);
        console.log('hsntsn_tsn>>>',hsntsn_tsn.value);
        // empty the liste secreen modell values
        var liste_modell = document.querySelector('[data-variable="liste_modell"]');
        var liste_marke = document.querySelector('[data-variable="liste_marke"]');
        var liste_KW = document.querySelector('[data-variable="liste_KW"]');
        var liste_Hubraum = document.querySelector('[data-variable="liste_Hubraum"]');
        
        console.log('<><><><>',liste_marke)
          liste_modell.innerHTML = '';
          liste_marke.innerHTML = '';
          liste_KW.innerHTML = '';
          liste_Hubraum.innerHTML = '';
        
        console.log(liste_modell);
        
        if( hsntsn_hsn.value != '' && hsntsn_tsn.value != ''){
           const res = fetch('https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/'+hsntsn_hsn.value+'&'+hsntsn_tsn.value)
                      .then(response =>{
                          if(response.ok){
                              return response.json();
                          }
                      }
                      )
                      .then(data =>{
                             console.log('Data>>>',data);
                             
                             let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
                             let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
                             let hsntsn_Kw = document.querySelector('[data-variable="hsntsn_KW"]');
                             let hsntsn_hubraum = document.querySelector('[data-variable="hsntsn_Hubraum"]');
                             hsntsn_marke.readOnly = true;
                             hsntsn_modell.readOnly = true;
                             hsntsn_Kw.readOnly = true;
                             hsntsn_hubraum.readOnly = true;
                             console.log('hsntsn_marke>>>',hsntsn_marke.value);
                             console.log('hsntsn_modell>>>',hsntsn_modell.value);
                             // Labels
                             let brandslabel = document.querySelector('label[for="input-2fde0836"]');
                             let carslabel = document.querySelector('label[for="input-34f090da"]');
                             
                             hsntsn_marke.style.visibility='visible';
                             hsntsn_modell.style.visibility='visible';
                             hsntsn_Kw.style.visibility='visible';
                             hsntsn_hubraum.style.visibility='visible';
                             
                            //  brandslabel.style.visibility = 'visible';
                            //  carslabel.style.visibility = 'visible';
                             hsntsn_marke.value = data.Fabrikmarke__c;
                             hsntsn_modell.value = data.Handelsname__c;
                             hsntsn_Kw.value = data.kW__c
                             hsntsn_hubraum.value = data.Hubraum__c
                      }
                      );
        }
        
        
   
    


//Hide the input and labels

 let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
 let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
 let hsntsn_Kw = document.querySelector('[data-variable="hsntsn_KW"]');
 let hsntsn_hubraum = document.querySelector('[data-variable="hsntsn_Hubraum"]');
 
 hsntsn_marke.readOnly = true;
 hsntsn_modell.readOnly = true;
 hsntsn_Kw.readOnly = true;
 hsntsn_hubraum.readOnly = true;
 console.log('hsntsn_marke>>>',hsntsn_marke.value);
 console.log('hsntsn_modell>>>',hsntsn_modell.value);

let brandslabel = document.querySelector('label[for="input-2fde0836"]');
let carslabel = document.querySelector('label[for="input-34f090da"]');
//brandslabel.style.visibility = 'hidden';
//carslabel.style.visibility = 'hidden';
hsntsn_marke.style.visibility = 'hidden';
hsntsn_modell.style.visibility = 'hidden';
hsntsn_Kw.style.visibility = 'hidden';
hsntsn_hubraum.style.visibility = 'hidden';

console.log('heyflow screen view: if Condition', event.detail);
window.addEventListener('heyflow-change', (event) => {
console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
console.log('heyflow change:', event.detail);
if(event.detail.fieldsSimple.HSN != undefined && event.detail.fieldsSimple.TSN != undefined){
console.log('event.detail.fieldsSimple.HSN',event.detail.fieldsSimple.HSN);
console.log('event.detail.fieldsSimple.TSN',event.detail.fieldsSimple.TSN);
let varhsn = event.detail.fieldsSimple.HSN;
let vartsn = event.detail.fieldsSimple.TSN;
let hsntsn = varhsn +'&'+vartsn;
const respone = fetch('https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/'+hsntsn) // api for the get request
.then(response =>{
 if(!response.ok){
     
     
     
     let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
     let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
     let hsntsn_Kw = document.querySelector('[data-variable="hsntsn_KW"]');
     let hsntsn_hubraum = document.querySelector('[data-variable="hsntsn_Hubraum"]');
     // Labels
     let brandslabel = document.querySelector('label[for="input-2fde0836"]');
     let carslabel = document.querySelector('label[for="input-34f090da"]');
     hsntsn_marke.style.visibility='hidden';
     hsntsn_modell.style.visibility='hidden';
     hsntsn_Kw.style.visibility='hidden';
     hsntsn_hubraum.style.visibility='hidden';
     
     brandslabel.style.visibility = 'hidden';
     carslabel.style.visibility = 'hidden';
     
     
     
     
     throw new Error(' response was not ok');
 }
 return response.json();
 
})
.then(data =>{

 console.log(data.Name);
 
 
 let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
 let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
 let hsntsn_Kw = document.querySelector('[data-variable="hsntsn_KW"]');
 let hsntsn_hubraum = document.querySelector('[data-variable="hsntsn_Hubraum"]');
 
 // Labels
 let brandslabel = document.querySelector('label[for="input-2fde0836"]');
 let carslabel = document.querySelector('label[for="input-34f090da"]');
 
 hsntsn_marke.style.visibility='visible';
 hsntsn_modell.style.visibility='visible';
 hsntsn_Kw.style.visibility='visible';
 hsntsn_hubraum.style.visibility='visible';
 
 //brandslabel.style.visibility = 'visible';
 //carslabel.style.visibility = 'visible';
 hsntsn_marke.value = data.Fabrikmarke__c;
 hsntsn_modell.value = data.Handelsname__c;
 hsntsn_Kw.value = data.kW__c
 hsntsn_hubraum.value = data.Hubraum__c
 
} );



}
});
}








if(event.detail.screenName === 'liste'){
    
    // empty the modelllist 
    
   
         
   
   // empty the values of hsntsn secreen inputs values
   let hsntsn_hsn = document.querySelector('[data-variable="hsntsn_HSN"]');
   let hsntsn_tsn = document.querySelector('[data-variable="hsntsn_TSN"]');
   let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
   let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
   let hsntsn_Kw = document.querySelector('[data-variable="hsntsn_KW"]');
   let hsntsn_hubraum = document.querySelector('[data-variable="hsntsn_Hubraum"]');
   hsntsn_hsn.value = '';
   hsntsn_tsn.value = '';
   hsntsn_marke.value = '';
   hsntsn_modell.value = '';
   hsntsn_Kw.value = '';
   hsntsn_hubraum.value = '';
 
   
    var liste_marke = document.querySelector('[data-variable="liste_marke"]');
    liste_marke.innerHTML = '';
    const defaultOption = document.createElement('option');
    defaultOption.textContent = "Bitte auswählen";
    defaultOption.value = "";
    defaultOption.disabled = true; // Make the default option disabled
    defaultOption.selected = true; 
    
    liste_marke.appendChild(defaultOption);
    liste_marke.disabled = true; 
    fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchmaker/")
    .then(response => response.json())
    .then(data =>{
    console.log(data.length)
    
    
    data.forEach(function(d) {
        let option = document.createElement('option');
        option.value = d;
        option.textContent = d;
        liste_marke.appendChild(option);
        liste_marke.disabled = false; 
    });
     
    
    
    } )
    .catch(error => console.error(error));
    console.log('heyflow screen view',event.detail);

// First Endpoint Hit End




// 2nd Endpoint Hit start


    var liste_marke1 = document.querySelector('[data-variable="liste_marke"]');
    
    let carslabel = document.querySelector('[id="id-620ded9c"]');
    
    
    
   if(!liste_marke1.hasListener){
    liste_marke1.addEventListener('change', () => {
        
        console.log('event.detail.screenName>>>>',event.detail.screenName);
        
        
        var liste_marke_value = document.querySelector('[data-variable="liste_marke"]');
     console.log(liste_marke_value.value);
     if(liste_marke_value.value == ''){
         
         
         
         var liste_modell = document.querySelector('[data-variable="liste_modell"]');
         for ( var i = newmodell.length - 1; i >= 0 ; i--) {
               liste_modell.options[i] = null;
             }
         }
      if(liste_marke_value.value != 'None'){
          
          var liste_modell = document.querySelector('[data-variable="liste_modell"]');
          for (i = liste_modell.length - 1; i >= 0 ; i--) {
     
               liste_modell.options[i] = null;
    
             }
            const defaultOption = document.createElement('option');
            defaultOption.textContent = "Bitte auswählen";
            defaultOption.value = "";
            defaultOption.disabled = true; // Make the default option disabled
            defaultOption.selected = true; 
            
            liste_modell.appendChild(defaultOption);
            liste_modell.disabled = true; 
            
           console.log(encodeURIComponent(liste_marke_value.value));
	       let encodedName = encodeURIComponent(liste_marke_value.value);
	       fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchModell/?modell=" + encodedName)
	       .then(response => response.json())
	       .then(data => {
		    console.log('data',data);
		    data.forEach(function(d) {
            let option = document.createElement('option');
            option.value = d;
            option.textContent = d;
            liste_modell.appendChild(option);
            liste_modell.disabled = false;
            });

		 })
	 .catch(error => console.error(error));
	 
          
      }
        
    });
    liste_marke1.hasListener = true;
       
   }
   
   // 2nd Endpoint hit end
   
   // 3rd Endpoint when Modell Changed
   
   var liste_modell = document.querySelector('[data-variable="liste_modell"]');
   
       
   if(!liste_modell.hasListener){
       
       liste_modell.addEventListener('change', () => {
           
             console.log('event.detail.screenName Modell Changed>>>>',event.detail.screenName);
             
             
             var liste_modell_value = document.querySelector('[data-variable="liste_modell"]');
             var liste_KW = document.querySelector('[data-variable="liste_KW"]');
             console.log(liste_modell_value.value);
             
             liste_KW.innerHTML = '';
             const defaultOption = document.createElement('option');
             defaultOption.textContent = "Bitte auswählen";
             defaultOption.value = "";
             defaultOption.disabled = true; // Make the default option disabled
             defaultOption.selected = true; 
            
             liste_KW.appendChild(defaultOption);
             liste_KW.disabled = true; 
             
             if(liste_modell_value.value != 'None'){
                 
                 
                   let encodedName = encodeURIComponent(liste_modell_value.value);
        	       fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchKW/?modell=" + encodedName)
        	       .then(response => response.json())
        	       .then(data => {
        		    console.log('data',data);
        		    
        		    data.forEach(function(d) {
                    let option = document.createElement('option');
                    option.value = d;
                    option.textContent = d;
                    liste_KW.appendChild(option);
                    liste_KW.disabled = false; 
                    });
        
        		    })
        	         .catch(error => console.error(error));
                         
             }
             
             
             
       });
       liste_modell.hasListener = true;
       
   }
   
   
   // 4th Endpoint start here
   
   var liste_KW = document.querySelector('[data-variable="liste_KW"]');
   
   if(!liste_KW.hasListener){
       
       liste_KW.addEventListener('change', () => {
           console.log('event.detail.screenName KW Changed>>>>',event.detail.screenName);
           
             var liste_KW_value = document.querySelector('[data-variable="liste_KW"]');
             console.log(liste_KW_value.value);
             var liste_modell_value = document.querySelector('[data-variable="liste_modell"]');
             console.log(liste_modell_value.value);
             
             var liste_Hubraum = document.querySelector('[data-variable="liste_Hubraum"]');
        	 liste_Hubraum.innerHTML = '';
        	 const defaultOption = document.createElement('option');
             defaultOption.textContent = "Bitte auswählen";
             defaultOption.value = "";
             defaultOption.disabled = true; // Make the default option disabled
             defaultOption.selected = true; 
            
             liste_Hubraum.appendChild(defaultOption);
             liste_Hubraum.disabled = true;
             
             if(liste_KW_value.value != 'None' && liste_modell_value.value != 'None'){
                 
                    let encodedKWName = encodeURIComponent(liste_KW_value.value);
                    let encodedModellName = encodeURIComponent(liste_modell_value.value);
        	        fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchHubraum/?kw=" + encodedKWName+"&modell="+encodedModellName)
        	       .then(response => response.json())
        	       .then(data => {
        		    console.log('data',data);
        		    
        		    data.forEach(function(d) {
                    let option = document.createElement('option');
                    option.value = d;
                    option.textContent = d;
                    liste_Hubraum.appendChild(option);
                    liste_Hubraum.disabled = false;
                    });
        
        		    })
        	         .catch(error => console.error(error));
                 
             }
           
       });
       liste_KW.hasListener = true;
       
   }
   
   

window.addEventListener('heyflow-change', (event) => {
    
     console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
     console.log('heyflow change fields:', event.detail.fields);
     
     console.log('heyflow change:', event.detail);
     
    
    
});





}





});



window.addEventListener('heyflow-submit', (event) => {
// console.log('heyflow submit:', event.detail);
});



/*window.addEventListener('heyflow-change', (event) => {

});*/